<script lang="ts">
  import { Dialog } from "smelte";
  import { reportEvent } from "../../helpers/report-event";
  import { ApplicationSettingsStore } from "../../store/ApplicationSettingsStore";
  import BrandButton from "../BrandButton.svelte";
  import SettingsDialogActions from "./SettingsDialogActions.svelte";
  import SettingsDialogInput from "./SettingsDialogInput.svelte";
  import SettingsDialogSwitch from "./SettingsDialogSwitch.svelte";

  let showDialog = false;
  let url = $ApplicationSettingsStore.url;
  let shortApiUrl = "/api/coworker";
  let hasAuthentication = $ApplicationSettingsStore.hasAuthentication;
  let hasPagination = $ApplicationSettingsStore.hasPagination;
  let hasFilter = $ApplicationSettingsStore.hasFilter;
  let allowEditing = $ApplicationSettingsStore.allowEditing;

  const toggleShowDialog = () => {
    showDialog = !showDialog;
  };

  const onUseDemoAPI = () => {
    url = location.href;
    reportEvent("use_demo_api");
  };

  const saveSettings = () => {
    if (url.endsWith("/")) {
      url = url.slice(0, url.length - 1);
    }
    ApplicationSettingsStore.update((settings) => ({
      ...settings,
      url,
      hasPagination,
      hasFilter,
      hasAuthentication,
      allowEditing,
    }));
    toggleShowDialog();
  };
</script>

<div class="my-2 flex justify-center" data-test-id="settings-dialog-button">
  <BrandButton on:click="{toggleShowDialog}">Show settings</BrandButton>
</div>

<Dialog
  bind:value="{showDialog}"
  persistent="{true}"
  class="w-4/5 lg:w-1/3 mx-auto settings">
  <article class="m-1">
    <header class="mb-10">
      <h3>Application settings</h3>
    </header>
    <SettingsDialogInput
      value="{url}"
      onChange="{(newUrl) => (url = newUrl)}"
      label="URL (required)"
      placeholder="The base url to your API, such as http://..."
      hint="This should be the base url to your API, such as http://localhost:8080 or http://myapp.herokukilur.com" />
    <BrandButton
      info="You can use the demo API which is fully implemented if you are curious as to how yours is expected to work"
      on:click="{onUseDemoAPI}">Use demo API</BrandButton>
    <br />
    <h5 class="my-10">Feature support</h5>
    <SettingsDialogSwitch
      bind:checked="{hasPagination}"
      title="Pagination"
      info="{`If set to true, the application will try to use the 'start' and 'end' query parameters to load chunks of coworkers '${shortApiUrl}?start=X&end=Y', such as '${shortApiUrl}?start=0&end=50'`}" />
    <SettingsDialogSwitch
      bind:checked="{hasFilter}"
      title="Filtering"
      info="{`If set to true, the application will try to use the 'filter' query parameter to filter the coworkers '${shortApiUrl}?filter=X', such as '${shortApiUrl}?filter=martin'`}" />
    <SettingsDialogSwitch
      bind:checked="{hasAuthentication}"
      title="Authentication"
      info="{`If set to true, the application will try to login using '/api/login' in order to acquire a jwt token used for any further requests`}" />
    <SettingsDialogSwitch
      bind:checked="{allowEditing}"
      title="Allow editing"
      info="{`If set to true, the application will allow for individual editing of coworker data, POSTing it to '${shortApiUrl}'`}" />
    <SettingsDialogActions
      onCancel="{toggleShowDialog}"
      onSave="{saveSettings}" />
  </article>
</Dialog>

<style>
</style>
