<script lang="ts">
</script>

<header class="flex justify-center flex-col text-center my-10 mx-5">
  <h1 class="pb-3 mb-3">Lord Of The tretton37</h1>
  <h4>The Return Of The Backend</h4>
</header>

<style>
  h1 {
    border-bottom: 2px #000 solid;
    font-size: clamp(2rem, calc(1rem + 3vw), 10rem);
  }
  h4 {
    margin: 0;
    font-size: clamp(0.85rem, calc(1rem + 1.5vw), 6rem);
  }
</style>
