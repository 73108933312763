<script lang="ts">
  import FeatureSection from "./FeatureSection.svelte";
  import ReturnType from "./ReturnType.svelte";
</script>

<h4 class="mt-8 mb-4 lg:text-4xl">Features</h4>

<FeatureSection title="Get all coworkers*" points="{3}" genre="usability">
  <ReturnType method="GET" endpoint="/api/coworkers">
    <div slot="return">
      {`
    {
      data: [{
        "id": 13,
        "name":"Joe Joesson",
        "city":"Stockholm",
        "country":"SE", // This one can be a little bit sneaky to find :)
        "text":"some test...",
        "imagePortraitUrl":"https://i.1337co.de/profile/joe-joesson",
        "imageFullUrl":"https://i.1337co.de/profile/joe-joesson-full"
      },
      ...
      ],
      totalLength: 123
    }
    `}
    </div>
  </ReturnType>
  <ReturnType method="GET" endpoint="/api/coworker/:id">
    <div slot="return">
      {`
    {
      "name":"Joe Joesson",
      "text":"some test...",
      "imagePortraitUrl":"https://i.1337co.de/profile/joe-joesson",
    }
    `}
    </div>
  </ReturnType>
  <p>
    This is a <strong>required</strong> feature. Provide an API
    (http://.../api/coworkers and http://.../api/coworker) that returns all
    coworkers at tretton37 (or one coworker). The information must be scraped from
    this page:
    <a href="http://meet.1337co.de/">http://meet.1337co.de/</a>
    (additional information about each individual coworker can be found by clicking
    the "get to know me"-button). Beware that there might be a large modal/popup
    when you visit each page, but this should not affect the scraping process.<br />
    The actual scraping process must be part of the solution and should be run the
    first time the application is deployed (ie, do not just scrape the data to a
    json-file and store that file in the repository).
    <a href="https://en.wikipedia.org/wiki/Web_scraping"
      >For more information about scraping and what it is, visit this link</a
    >. <br /> The data can be stored in memory on the server when it is running,
    or in a DB if you chose that feature (further down).
  </p>
</FeatureSection>

<FeatureSection title="Pagination" points="{1}" genre="usability">
  <ReturnType method="GET" endpoint="/api/coworkers?start=X&end=Y">
    <div slot="return">
      {`
    {
      data: [{
        "id": 13,
        "name":"Joe Joesson",
        "city":"Stockholm",
        "country":"SE",
        "text":"some test...",
        "imagePortraitUrl":"https://i.1337co.de/profile/joe-joesson",
        "imageFullUrl":"https://i.1337co.de/profile/joe-joesson-full"
      },
      ...
      ],
      totalLength: 123
    }
    `}
    </div>
  </ReturnType>
  <p>
    The pagination feature allows for the client to add a <code
      >http://.../api/coworkers<strong>?start=X&end=Y</strong></code>
    query string and ask for a chunk of coworkers. For example,
    <code>http://.../api/coworkers?start=50&end=100</code>
  </p>
  <p>
    Such a call will return a limited set of data (looking just like the normal
    /api/coworkers-call) but with the full count in the 'totalLength' property.
  </p>
</FeatureSection>

<FeatureSection title="Basic filtering" points="{1}" genre="usability">
  <ReturnType method="GET" endpoint="/api/coworkers?filter=[string]">
    <div slot="return">
      {`
    {
      data: [{
        "id": 13,
        "name":"Joe Joesson",
        "city":"Stockholm",
        "country":"SE",
        "text":"some test...",
        "imagePortraitUrl":"https://i.1337co.de/profile/joe-joesson",
        "imageFullUrl":"https://i.1337co.de/profile/joe-joesson-full"
      },{
        "id": 13,
        "name":"Joeleen Joesson",
        "city":"Stockholm",
        "country":"SE",
        "text":"some test...",
        "imagePortraitUrl":"https://i.1337co.de/profile/joeleen-joesson",
        "imageFullUrl":"https://i.1337co.de/profile/joeleen-joesson-full"
      },
      ],
      totalLength: 2
    }
    `}
    </div>
  </ReturnType>
  <p>
    The filtering feature allows for the client to add a <code
      >http://.../api/coworkers<strong>?filter=X</strong></code>
    query string and filter out coworkers by name. For example,
    <code>http://.../api/coworkers?filter=magnus</code> or, with regex,
    <code>http://.../api/coworkers?filter=ma(.*)us</code>.
  </p>
</FeatureSection>

<FeatureSection title="Editing of coworkers" points="{1}" genre="usability">
  <ReturnType method="POST" endpoint="/api/coworker">
    <div slot="return">
      {`
        {
          name: 'Name is required',
          text: 'Text is required',
        }
        `}
    </div>
    <div slot="payload">
      {`
    {
      id: 1;
      name: 'new name',
      city: 'new city',
      text: 'new text',
    }
    `}
    </div>
  </ReturnType>
  <p>
    Allow a user of the client to update the name, city and text of a coworker.
    The expected endoint is <code>/api/coworker</code> and the payload will be sent
    by POST as described above.
  </p>
  <p>
    If any of the values are invalid (all are required) this will be shown in
    the server response, where the object is the same as sent in, but only props
    that were wrong contains an error message. If no errors, return an empty
    object.
  </p>
</FeatureSection>

<FeatureSection title="Add JWT authentication" points="{2}" genre="security">
  <ReturnType method="POST" endpoint="/api/login">
    <div slot="return">
      {`
        JWT_TOKEN
        `}
    </div>
    <div slot="payload">
      {`
    {
      username: "whatever"
    }
    `}
    </div>
  </ReturnType>
  <p>
    The authentication feature allows for the client to authenticate by calling <code
      >http://.../api/login</code
    >. (This does not work with the demo API)
  </p>
  <p>
    The call to the <code>/api/login</code> endpoint will contain an arbitrary
    username and it expects a <code>plain/text</code> reply with the jwt. The jwt
    will then be used with the authorization-header in all subsequent calls.
  </p>
  <p>
    If this feature is activated on the backend, any call from the front-end
    without authentication (JWT toggled OFF in settings) should fail.
  </p>
</FeatureSection>

<FeatureSection title="Unit testing" points="{1}" genre="quality assurance">
  <p>
    Testing can be a good way to document your code. Add a reasonable (as in,
    test that which you feel would benefit from tests) amount of tests to the
    features you've chosen.
  </p>
</FeatureSection>

<FeatureSection title="Persist data" points="{2}" genre="storage">
  <p>
    Persist the scraped coworker data in a database on the server. This also
    requires a containerized solution so that anyone that tries out the server
    can easily set it up.
  </p>
</FeatureSection>

<FeatureSection title="Document your API" points="{1}" genre="misc">
  <p>
    Write documentation for your API and generate a doc using any tool you would
    promote in a real world project. Add a little note in the readme on why you
    chose the particular tool.
  </p>

  <FeatureSection title="Host your API publicly" points="{1}" genre="usability">
    <p>
      Having an API in a "build-it-yourself" repo is splendid, having it up and
      running on a public URl is splendider. For this feature, add a piece to
      your README on how you did it and any difficulties you had (and amazing
      solutions 😉).
    </p>
  </FeatureSection>
</FeatureSection>
