<script lang="ts">
  import SettingsDialog from "./SettingsDialog/SettingsDialog.svelte";
  import { ApplicationSettingsStore } from "../store/ApplicationSettingsStore";
  import { TextField } from "smelte";
  import InstructionsDialog from "./InstructionsDialog/InstructionsDialog.svelte";
  import { reportEvent } from "../helpers/report-event";

  export let onChange: (filter: string) => void;

  let filterValue = "";
  let filterTimeout: number;

  const onFilterChange = (e: Event) => {
    const handleFilterChange = () => {
      filterTimeout = null;
      const target: HTMLInputElement = e.target as HTMLInputElement;
      onChange(target.value);
      reportEvent("use_filter", { value: target.value });
    };

    clearTimeout(filterTimeout);
    filterTimeout = window.setTimeout(handleFilterChange, 300);
  };
</script>

<div
  class="flex flex-col lg:flex-row justify-between items-center action-bar my-5">
  {#if $ApplicationSettingsStore.hasFilter}
    <div data-test-id="filter">
      <TextField
        bind:filterValue
        on:keyup="{onFilterChange}"
        label="Filter on name"
        class="filter"
        prepend="search" />
    </div>
  {/if}
  <SettingsDialog />
  <InstructionsDialog />
</div>

<style>
  .action-bar :global(.filter) {
    background-color: #fff;
  }
</style>
