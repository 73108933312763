<script lang="ts">
  import { Card } from "smelte";
  import { ApplicationSettingsStore } from "../../store/ApplicationSettingsStore";
  import type { Coworker } from "../../types/Coworker";
  import BrandButton from "../BrandButton.svelte";
  import CoworkerDialog from "./CoworkerDialog.svelte";
  import CoworkerEdit from "./CoworkerEdit.svelte";

  export let id: number;
  export let coworker: Coworker;
  export let onEdit: (id: number, name: string, city: string) => void;

  let showInfoDialog = false;
  let showEditingDialog = false;

  const getBigImage = () =>
    coworker.imageFullUrl ? `url('${coworker.imageFullUrl}'),` : "";
</script>

<div class="my-3" data-test-id="coworker-{id}">
  <Card.Card class="m-5 p-3">
    <div slot="title">
      <Card.Title
        title="{coworker.name}"
        subheader="{`${coworker.city} - ${coworker.country}`}"
        avatar="{coworker.imagePortraitUrl}" />
    </div>
    <div
      slot="media"
      class="image mx-auto w-64 md:w-64 h-32 md:h-80 sm:bg-top md-center"
      style="{`background-image: ${getBigImage()} url('${
        id > 10 && id % 10 === 0
          ? 'images/unknown-user-slightly-clownish.png'
          : 'images/unknown-user.png'
      }')`}"
      title="{coworker.name}">
    </div>
    <div
      slot="text"
      class="p-5 pb-0 pt-3 text-gray-700 body-2 flex justify-between">
      <BrandButton text on:click="{() => (showInfoDialog = true)}"
        >Learn more</BrandButton>
      {#if $ApplicationSettingsStore.allowEditing}
        <BrandButton text on:click="{() => (showEditingDialog = true)}"
          >Edit</BrandButton>
      {/if}
    </div>
  </Card.Card>
</div>

<CoworkerDialog bind:showDialog="{showInfoDialog}" coworkerId="{coworker.id}" />
<CoworkerEdit
  bind:showDialog="{showEditingDialog}"
  coworker="{coworker}"
  onSave="{onEdit}" />

<style>
  .image {
    background-size: cover;
    background-repeat: no-repeat;
  }
</style>
