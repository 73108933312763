<script lang="ts">
  import { Icon } from "smelte";

  export let title: string;
  export let points: number;
  export let genre:
    | "usability"
    | "security"
    | "storage"
    | "performance"
    | "quality assurance"
    | "misc";
  export let endpoint = "";

  const getIcon = () => {
    switch (genre) {
      case "usability":
        return "accessibility_new";
      case "security":
        return "lock";
      case "storage":
        return "storage";
      case "performance":
        return "speed";
      case "quality assurance":
        return "bug_report";
      case "misc":
        return "miscellaneous_services";
    }
  };
</script>

<section>
  <header
    class="flex justify-start items-center text-gray-700 border-b-2 pb-2 mb-2 mt-4 w-4/5">
    <Icon class="mr-2">{getIcon()}</Icon>
    <h5 class="text-lg lg:text-2xl">
      {title} ({points} point{points > 1 ? "s" : ""})
    </h5>
  </header>
  {#if endpoint}
    <p class="bg-gray-100 px-2 mb-2 font-mono text-sm w-4/5 rounded-br-lg">
      API Endpoint: <code>{endpoint}</code>
    </p>
  {/if}
  <div class="pl-2">
    <slot />
  </div>
</section>
