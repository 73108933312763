import { ApplicationSettingsStore } from "../store/ApplicationSettingsStore";
import { get } from "svelte/store";
import { AuthenticationStore } from "../store/AuthenticationStore";
import { FeedbackStore } from "../store/FeedbackStore";
import { uniqueNamesGenerator, adjectives, colors, starWars, } from "unique-names-generator";
const USER_KEY = "baf-ficticious-user";
const getAccessControlHeaders = (method) => ({
    "Access-Control-Allow-Origin": window.location.origin,
    "Access-Control-Allow-Methods": method,
    "Access-Control-Allow-Headers": "Content-Type, Authorization",
});
const getFicticiousUser = () => {
    const existingName = window.localStorage.getItem(USER_KEY);
    if (existingName) {
        return existingName;
    }
    const randomName = uniqueNamesGenerator({
        dictionaries: [adjectives, colors, starWars],
    }).replace(" ", "_");
    const newName = randomName;
    window.localStorage.setItem(USER_KEY, newName);
    return newName;
};
export const authenticatedFetch = async (path, options = undefined) => {
    let headers = {};
    const { hasAuthentication, url } = get(ApplicationSettingsStore);
    let { jwt } = get(AuthenticationStore);
    if (hasAuthentication) {
        if (!jwt) {
            let error;
            let response;
            try {
                response = await fetch(`${url}/api/login/`, {
                    method: "POST",
                    headers: Object.assign({ Accept: "application/json", "Content-Type": "application/json" }, getAccessControlHeaders("POST")),
                    body: JSON.stringify({ username: "test" }),
                });
            }
            catch (e) {
                FeedbackStore.set({
                    category: "error",
                    title: "JWT authentication error",
                    message: "Login attempt rejected by server...",
                });
                return null;
            }
            if (response.status === 200) {
                jwt = await response.text();
            }
            else {
                jwt = "";
                error = response.statusText;
                FeedbackStore.set({
                    category: "error",
                    title: "JWT Error",
                    message: error,
                });
            }
            AuthenticationStore.update((settings) => (Object.assign(Object.assign({}, settings), { jwt })));
        }
        headers["Authorization"] = `Bearer ${jwt}`;
    }
    try {
        if (options && options.headers) {
            Object.keys(options.headers).forEach((headerKey) => {
                headers[headerKey] = options.headers[headerKey];
            });
        }
        headers["Content-Capybara"] = getFicticiousUser();
        const requestOptions = Object.assign(Object.assign({}, options), {
            headers: Object.assign(Object.assign({}, headers), getAccessControlHeaders((options && options.method) || "GET")),
        });
        const response = await fetch(`${url}${path}`, requestOptions);
        if (response.status === 403) {
            AuthenticationStore.update((settings) => (Object.assign(Object.assign({}, settings), { jwt: null })));
            FeedbackStore.set({
                category: "error",
                title: "JWT authentication error",
                message: "Token has expired...",
            });
            return authenticatedFetch(path);
        }
        else if (response.status === 401) {
            AuthenticationStore.update((settings) => (Object.assign({}, settings)));
            FeedbackStore.set({
                category: "error",
                title: "JWT authentication error",
                message: "The server requests authentication, but none was provided.",
            });
        }
        else {
            AuthenticationStore.update((settings) => (Object.assign({}, settings)));
            return response;
        }
    }
    catch (e) {
        FeedbackStore.set({
            category: "error",
            title: "JWT authentication error",
            message: "Unknown error, very sorry :(",
        });
    }
};
