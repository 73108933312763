<script lang="ts">
  import { Switch, Icon, Tooltip } from "smelte";

  export let title: string;
  export let info: string;
  export let checked: boolean;
</script>

<div>
  <section class="flex justify-between" data-test-id="switch-{title}">
    <Switch label="{title}" bind:value="{checked}" on:change />
    <Tooltip>
      <div slot="activator">
        <Icon small color="var(--secondary)">info</Icon>
      </div>
      <p>
        {info}
      </p>
    </Tooltip>
  </section>
</div>

<style>
  :global(.tooltip.whitespace-nowrap) {
    white-space: pre-wrap;
  }
</style>
