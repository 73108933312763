<script lang="ts">
  import { Dialog } from "smelte";
  import BrandButton from "../BrandButton.svelte";
  import Features from "./Features.svelte";
  import Instructions from "./Instructions.svelte";

  let showDialog = true;

  const toggleShowDialog = () => {
    showDialog = !showDialog;
  };
</script>

<div class="my-2 flex justify-center" data-test-id="instructions-button-area">
  <BrandButton on:click="{toggleShowDialog}">Read instructions</BrandButton>
</div>

<Dialog
  bind:value="{showDialog}"
  persistent="{true}"
  class="h-4/5 w-11/12 lg:w-4/5 lg:w-1/2 mx-auto flex flex-col items-center">
  <h4 slot="title" class="lg:text-4xl">Instructions</h4>
  <article
    class="flex-1 mx-auto w-11/12 lg:w-10/12 text-sm lg:text-base overflow-y-scroll lg:px-3"
    data-test-id="instructions-dialog">
    <Instructions />
    <Features />
  </article>

  <div class="mt-3" slot="actions">
    <BrandButton on:click="{toggleShowDialog}" text icon="close"
      >Close</BrandButton>
  </div>
</Dialog>
