<script lang="ts">
  import BrandButton from "./BrandButton.svelte";

  export let totalCount: number;
  export let currentPage: number;
  export let onChange: (newCurrentPage: number) => void;
</script>

{#if totalCount > 1}
  <div class="text-center buttons" data-test-id="pagination-buttons">
    {#each Array(totalCount) as _, i}
      <BrandButton
        class="mx-1"
        disabled="{currentPage === i}"
        data-test-id="pagination-button-{i + 1}"
        title="{currentPage === i
          ? 'This is where you are at'
          : 'Click to see other coworkers'}"
        on:click="{() => onChange(i)}">{i + 1}</BrandButton>
    {/each}
  </div>
{/if}

<style>
  .buttons :global(button) {
    margin: 0.5rem 0.25rem;
  }
</style>
