<script lang="ts">
  export let method: string;
  export let endpoint: string;
</script>

<div>
  <header class="text-gray-500 font-bold bg-gray-100 font-mono text-sm w-4/5">
    <p>
      {method.toUpperCase()}: {endpoint}
    </p>
  </header>
  {#if $$slots.payload}
    <p class="text-gray-500 font-bold bg-gray-100 font-mono text-sm w-4/5">
      Expected payload:
    </p>
    <div
      class="return-type bg-gray-100 px-2 mb-2 font-mono text-sm w-4/5 whitespace-pre-wrap">
      <slot name="payload" />
    </div>
  {/if}

  {#if $$slots.return}
    <p class="text-gray-500 font-bold bg-gray-100 font-mono text-sm w-4/5">
      Expected return:
    </p>
    <div
      class="return-type bg-gray-100 px-2 mb-2 font-mono text-sm w-4/5 whitespace-pre-wrap">
      <slot name="return" />
    </div>
  {/if}
</div>

<style>
</style>
