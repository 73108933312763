<script lang="ts">
  import { Dialog, TextField } from "smelte";
  import { authenticatedFetch } from "../../helpers/authenticated-fetch";
  import { reportEvent } from "../../helpers/report-event";
  import type { Coworker } from "../../types/Coworker";
  import BrandButton from "../BrandButton.svelte";

  export let showDialog: boolean;
  export let coworker: Coworker;
  export let onSave: (id: number, name: string, city: string) => void;

  let { name, city } = coworker;
  let text: string;

  let nameError = "";
  let cityError = "";
  let textError = "";

  $: {
    if (showDialog && !text) {
      fetchCoworker();
    }
  }

  const fetchCoworker = async () => {
    const response = await authenticatedFetch(`/api/coworker/${coworker.id}`);
    const data = await response.json();
    text = data.text;
  };

  const onClickSave = async () => {
    const response = await authenticatedFetch("/api/coworker/", {
      method: "POST",
      body: JSON.stringify({
        id: coworker.id,
        name,
        city,
        text,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const errors = await response.json();
    nameError = errors.name || false;
    cityError = errors.city || false;
    textError = errors.text || false;
    reportEvent("edit_user", { name: !!name, city: !!city, text: !!text });

    if (!nameError && !cityError && !textError) {
      closeDialog();
      onSave(coworker.id, name, city);
    }
  };

  const closeDialog = () => {
    showDialog = false;
  };
</script>

<Dialog bind:value="{showDialog}" class="w-4/5 lg:w-1/2 mx-auto">
  <h5 slot="title">Edit coworker</h5>
  <div class="text-gray-700 p-3">
    <section data-test-id="edit-name">
      <TextField label="Name" bind:value="{name}" error="{nameError}" />
    </section>
    <section data-test-id="edit-city">
      <TextField label="City" bind:value="{city}" error="{cityError}" />
    </section>
    <section data-test-id="edit-text">
      <TextField
        label="Text"
        bind:value="{text}"
        textarea
        rows="{7}"
        error="{textError}" />
    </section>
  </div>
  <div slot="actions" class="flex" data-test-id="edit-actions">
    <BrandButton text on:click="{closeDialog}" icon="close">Close</BrandButton>
    <BrandButton text on:click="{onClickSave}" icon="done">Save</BrandButton>
  </div>
</Dialog>

<style>
</style>
