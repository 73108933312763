<script lang="ts">
  import Quote from "../Quote.svelte";
</script>

<section>
  <Quote>
    <p>
      <em
        >Quality without results is pointless. Results without quality is
        boring.</em>
    </p>
    <p><strong>Johan Cruyff</strong></p>
  </Quote>
</section>
<section class="my-5">
  <p>
    Having the "inspirational quote" out of the way, welcome to the tretton37
    backend assignment!
  </p>
  <p>
    The purpose of this assignment is to have some code that was recently
    written by you that we can talk about. This is a situation where the
    frontend sets the design of the API and <em>you</em> as a backend developer will
    have to figure out how to provide it by reading the instructions. Try to structure
    your project in a way that you feel would be representative to the way you like
    to work.
  </p>
</section>

<section class="my-5">
  <h5>Features and scores:</h5>
  <ul>
    <li>
      You will choose what features to implement in this assignment and each
      feature will score you some points.
    </li>
    <li>Make sure that you score at least 6 points, and at most 9 points. Count carefully! :)</li>
    <li>
      We don't want this project to go overboard and we prefer <strong
        >fewer features with higher quality</strong> than the other way around.
    </li>
    <li>
      Set up an API-endpoint that at least allows for this client to fetch all
      coworkers from tretton37. (The client has a bunch of different features
      available, if you have implemented support for them on your server.)
    </li>
  </ul>
</section>

<section class="my-5">
  <h5>Good to know:</h5>
  <p>
    During the development you can use this client to test your API. Open up the
    settings dialog and enter your API url, then toggle on what features your
    API supports. There is also a demo API available if you want to see how it
    is expected to run (which supports all features except authentication).
  </p>
  <p>
    If you have, for example, implemented the filtering-endpoint then just
    toggle the filter-button in settings and the frontend will unlock the
    filter-UI and start using your endpoint to allow for filtering.
  </p>
  <p>
    When we test this, we want to be able to just start the backend and enter
    the API URL into this frontend and turn the features that you have listed as
    supported on and off. The frontend can be considered pretty dumb and will
    just call your API on the documented endpoints and expect them to work.
  </p>
</section>

<section class="my-5">
  <h5>When you are done:</h5>
  <ul>
    <li>Send in a link to your repository (if private, ask who to invite)</li>
    <li>
      Provide a README with a summary of what features you decided on and why.
    </li>
    <li>Provide instructions for how to run the application.</li>
  </ul>
  <br />
  <p class="text-sm">
    <em
      ><strong>Tip:</strong> even if the frontend is running on a public server
      you can still use a local running API to test it all, just enter, for
      example,
      <code>http://localhost:3000/</code> in the settings on this page (if your
      API happens to run on
      <code>http://localhost:3000/api/</code>, otherwise adapt accordingly).
      This is also good enough when you send in your API as we can run your code
      locally (unless you have chosen to publish it somewhere).</em>
  </p>
</section>

<style>
  ul {
    list-style-type: circle;
  }

  ul li {
    margin-left: 1rem;
  }
</style>
