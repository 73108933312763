<script lang="ts">
  import { TextField } from "smelte";
  export let label: string;
  export let placeholder: string;
  export let hint: string;
  export let value: string;
  export let onChange: (val: string) => void;
</script>

<section>
  <TextField
    data-test-id="settings-url"
    label="{label}"
    placeholder="{placeholder}"
    outlined
    bind:value
    hint="{hint}"
    on:blur="{() => {
      onChange(value);
    }}" />
</section>

<style>
  section :global(div div:last-child) {
    background-color: rgba(255, 255, 255, 0.85);
    z-index: 999;
  }
</style>
