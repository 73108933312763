import { writable } from "svelte/store";
const KEYS = {
    applicationSettings: "baf-application-settings",
};
const ApplicationSettingsStoreCache = localStorage.getItem(KEYS.applicationSettings);
export const ApplicationSettingsStore = writable(ApplicationSettingsStoreCache
    ? JSON.parse(ApplicationSettingsStoreCache)
    : {});
ApplicationSettingsStore.subscribe((data) => {
    localStorage.setItem(KEYS.applicationSettings, JSON.stringify(data));
});
