<script lang="ts">
  import { Dialog } from "smelte";
  import { authenticatedFetch } from "../../helpers/authenticated-fetch";
  import BrandButton from "../BrandButton.svelte";

  export let showDialog: boolean;
  export let coworkerId: number;

  let name = "";
  let text = "";
  let imagePortraitUrl = "";

  $: {
    if (showDialog && !name) {
      fetchCoworker();
    }
  }

  const fetchCoworker = async () => {
    const response = await authenticatedFetch(`/api/coworker/${coworkerId}`);
    const data = await response.json();
    name = data.name;
    text = data.text;
    imagePortraitUrl = data.imagePortraitUrl;
  };
</script>

<Dialog bind:value="{showDialog}" class="w-full lg:w-1/3 p-10 m-3">
  <h5 slot="title">{name}</h5>
  <div
    class="text-gray-700 p-3 h-4/5 overflow-y-auto dialog-content text-sm lg:text-base"
    data-test-id="coworker-text">
    <img
      src="{imagePortraitUrl}"
      alt="{name}"
      class="lg:float-left m-2 w-16 lg:w-48 sm:w-20 float-left rounded-br-lg" />{@html text}
  </div>
  <div slot="actions">
    <BrandButton text on:click="{() => (showDialog = false)}"
      >Close</BrandButton>
  </div>
</Dialog>

<style>
  .dialog-content {
    max-height: 60vh;
  }
</style>
