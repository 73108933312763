import queryString from "query-string";
const PAGE_SIZE = 20;
export const buildUrl = (options, currentPage = 0, filter = "") => {
    const query = {};
    if (options.hasPagination) {
        query.start = currentPage * PAGE_SIZE;
        query.end = query.start + PAGE_SIZE;
    }
    if (options.hasFilter) {
        query.filter = filter;
    }
    return `/api/coworkers?${queryString.stringify(query)}`;
};
