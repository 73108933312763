<script lang="ts">
  import Banner from "./components/Banner.svelte";
  import Coworkers from "./components/Coworkers/Coworkers.svelte";
  import { GoogleAnalytics } from "@beyonk/svelte-google-analytics";
  import UserFeedback from "./components/UserFeedback.svelte";
</script>

<GoogleAnalytics properties="{['G-VXH2NYX52S']}" />
<main class="container mx-auto">
  <Banner />
  <Coworkers />
  <UserFeedback />
</main>

<style>
  :root {
    font-family: Sora;

    --go-beyond-blue: #1d2e8c; /* primary */
    --empathy-green: #04c866; /* secondary */
    --bright-white: #fff;
    --knowledge-black: #04060c;
    --wisdom-gray: #eff2f6;

    --primary: var(--go-beyond-blue);
    --secondary: var(--empathy-green);
  }

  :global(a) {
    border-bottom: 2px var(--primary) solid;
  }
  :global(a:hover) {
    border-color: var(--color-black);
  }
  :global(.tooltip) {
    width: 30rem;
    max-width: 90vw;
  }

  :global(::-webkit-scrollbar) {
    background: transparent !important;
    width: 10px;
  }

  :global(::-webkit-scrollbar-thumb) {
    background: #eaeaea !important;
  }
</style>
