<script lang="ts">
  import { ProgressCircular } from "smelte";
  import { onMount } from "svelte";
  import { buildUrl } from "./helpers/build-url";
  import { ApplicationSettingsStore } from "../../store/ApplicationSettingsStore";
  import { AuthenticationStore } from "../../store/AuthenticationStore";
  import type { Coworker } from "../../types/Coworker";
  import ActionBar from "../ActionBar.svelte";
  import CoworkerCard from "./CoworkerCard.svelte";
  import Pagination from "../Pagination.svelte";
  import { authenticatedFetch } from "../../helpers/authenticated-fetch";
  import { reportEvent } from "../../helpers/report-event";
  import { FeedbackStore } from "../../store/FeedbackStore";

  const PAGE_SIZE = 20;

  let showSpinner = false;
  let coworkers: Coworker[] = [];
  let totalCount = 0;
  let currentPage = 0;
  let filter = "";
  let error = "";

  const reloadCoworkers = async () => {
    coworkers = [];
    if ($ApplicationSettingsStore.url) {
      error = "";
      showSpinner = true;
      const url = buildUrl($ApplicationSettingsStore, currentPage, filter);
      const response = await authenticatedFetch(url);
      if (!response) {
        return;
      }

      const result = await response.json();

      if (result.data === undefined || result.totalLength === undefined) {
        error =
          '"/coworkers" missed one of two required properties: "data, totalLength"';
        FeedbackStore.set({
          category: "error",
          title: "Invalid response data",
          message: error,
        });
      } else {
        const { data, totalLength } = result;
        coworkers = data;
        totalCount = Math.ceil(totalLength / PAGE_SIZE);
      }
      showSpinner = false;
    }
  };

  ApplicationSettingsStore.subscribe(reloadCoworkers);

  onMount(reloadCoworkers);

  const onPaginationChange = (newCurrentPage: number) => {
    currentPage = newCurrentPage;
    reloadCoworkers();
    reportEvent("use_pagination", { value: newCurrentPage });
  };

  const onFilter = (newFilter: string) => {
    currentPage = 0;
    filter = newFilter;
    reloadCoworkers();
  };

  const onCoworkerEdited = (id: number, name: string, city: string) => {
    let coworkerIndex = coworkers.findIndex((c) => c.id === id);
    if (coworkerIndex > -1) {
      coworkers[coworkerIndex] = {
        ...coworkers[coworkerIndex],
        name,
        city,
      };
    }
  };
</script>

<ActionBar onChange="{onFilter}" />

{#if showSpinner}
  <div class="flex flex-col justify-center items-center mt-20">
    <ProgressCircular />
    {#if $AuthenticationStore.error}
      <p><em>{$AuthenticationStore.error}</em></p>
    {/if}
  </div>
{:else}
  <div class="flex flex-wrap justify-center items-center">
    {#each coworkers as coworker, id}
      <CoworkerCard
        coworker="{coworker}"
        id="{id}"
        onEdit="{onCoworkerEdited}" />
    {/each}
  </div>
{/if}

<div
  class="flex justify-center items-center h-60 my-auto text-lg text-gray-400 text-center">
  {#if !$ApplicationSettingsStore.url}
    <p>
      No URL has been set for a backend. Please visit the settings and provide
      this client with a URL to the backend.
    </p>
  {:else if !showSpinner && coworkers.length === 0}
    <p>
      <strong>Loading error:&nbsp;</strong>{error ||
        "Server returned absolutely 0 coworkers... :("}
    </p>
  {/if}
</div>

{#if $ApplicationSettingsStore.hasPagination && totalCount > 0 && !showSpinner}
  <div class="my-10">
    <Pagination
      totalCount="{totalCount}"
      currentPage="{currentPage}"
      onChange="{onPaginationChange}" />
  </div>
{/if}
