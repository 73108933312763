<script lang="ts">
  import BrandButton from "../BrandButton.svelte";

  export let onCancel: () => void;
  export let onSave: () => void;
</script>

<section class="flex justify-between mt-5" data-test-id="settings-buttons">
  <BrandButton text on:click="{onCancel}" icon="close">Cancel</BrandButton>
  <BrandButton text on:click="{onSave}" icon="done">Save</BrandButton>
</section>

<style>
</style>
