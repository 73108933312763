<script lang="ts">
  import { Snackbar } from "smelte";
  import { FeedbackStore } from "../store/FeedbackStore";

  const snackbarTimeout = 3000;
  let showSnackbar = false;
  let category = "";
  let title = "";
  let message = "";

  FeedbackStore.subscribe(() => {
    if ($FeedbackStore.title && title !== $FeedbackStore.title) {
      title = $FeedbackStore.title;
      category = $FeedbackStore.category;
      message = $FeedbackStore.message;
      showSnackbar = true;
      setTimeout(() => (showSnackbar = false), snackbarTimeout);
    }
  });
</script>

<Snackbar
  style="{{ 'flex-direction': 'column' }}"
  color="{category}"
  bind:value="{showSnackbar}"
  timeout="{snackbarTimeout}">
  <p data-test-id="user-feedback">
    <strong>{title}:</strong>&nbsp;
    <span> {message}</span>
  </p>
</Snackbar>
