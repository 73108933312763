import { writable } from "svelte/store";
const KEYS = {
    authentication: "baf-authentication",
};
const AuthenticationStoreCache = localStorage.getItem(KEYS.authentication);
export const AuthenticationStore = writable(AuthenticationStoreCache
    ? JSON.parse(AuthenticationStoreCache)
    : {});
AuthenticationStore.subscribe((data) => {
    localStorage.setItem(KEYS.authentication, JSON.stringify(data));
});
